import { ClipUtils } from '@air/utils-clips';
import { useEffect, useState } from 'react';

const REWIND_INTERVAL = 100;

export type UseVideoRewindParams = {
  playbackSpeed?: number;
  onResetPlaybackStep: () => void;
  videoElement: HTMLVideoElement | null;
};

export const useVideoRewind = ({ onResetPlaybackStep, playbackSpeed, videoElement }: UseVideoRewindParams) => {
  const [isRewinding, setIsRewinding] = useState(false);
  useEffect(() => {
    if (playbackSpeed && playbackSpeed <= 0 && videoElement) {
      /**
       * We need to pause the video before we start rewinding it to avoid the video from
       * flickering at lower playback speeds.
       */
      if (!videoElement.paused) {
        videoElement.pause();
      }

      const rewindInterval = setInterval(() => {
        if (videoElement.currentTime > 0) {
          setIsRewinding(true);
          ClipUtils.changeVideoTimeInSeconds(videoElement, (REWIND_INTERVAL / 1000) * playbackSpeed);
        } else {
          setIsRewinding(false);
          clearInterval(rewindInterval);
          onResetPlaybackStep();
        }
      }, REWIND_INTERVAL);

      return () => {
        setIsRewinding(false);
        clearInterval(rewindInterval);
      };
    }
  }, [onResetPlaybackStep, playbackSpeed, videoElement]);

  return { isRewinding };
};
