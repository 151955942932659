import { useCallback, useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
export default function useIsCmdPressed() {
    const [pressed, setPressed] = useState(false);
    const cancelPressed = useCallback(()=>setPressed(false), [
        setPressed
    ]);
    useHotkeys("*", (event)=>{
        if (event.key === "Control" || event.key === "Meta") {
            if (event.type === "keydown") {
                setPressed(true);
            } else if (event.type === "keyup") {
                setPressed(false);
            }
        }
    }, {
        keydown: true,
        keyup: true,
        enableOnFormTags: [
            "INPUT",
            "TEXTAREA"
        ]
    });
    // Cancel pressed when browser loses focus, e.g. it is minimzed
    useEffect(()=>{
        window.addEventListener("blur", cancelPressed);
        return ()=>{
            window.removeEventListener("blur", cancelPressed);
        };
    }, [
        cancelPressed
    ]);
    return pressed;
}
