import { Discussions } from '@air/api';
import { Board, Clip, User } from '@air/api/types';
import { reportErrorToBugsnag } from '@air/utils-error';
import { Query, QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';

import { ClipDiscussion, FreeformAnnotation } from '~/components/Annotations/shared/types';
import { NEW_DISCUSSION_ID } from '~/components/Annotations/shared/useActiveDiscussionIdObserver';
import { ASSET_MODAL_DISCUSSION_FILTERS } from '~/components/AssetModal/shared/context/DiscussionsPanelContext/discussionsPanelContextTypes';
import { ClipDiscussionsCacheType } from '~/swr-hooks/discussions/types';
import {
  createDiscussionInCache,
  getDiscussionsParams,
  isClipDiscussionsKey,
  mockDiscussion,
} from '~/swr-hooks/discussions/utils';

interface CreateDiscussionParams {
  account: Pick<User, 'avatar' | 'firstName' | 'id' | 'lastName'>;
  board?: Partial<Pick<Board, 'id' | 'title'>>;
  clipId: Clip['id'];
  currentVersion: number;
  key: QueryKey;
  newComment: string;
  shortId?: string;
  assetId: Clip['assetId'];
  type: 'clip';
  timestamp?: number;
  annotation?: FreeformAnnotation;
  beforeCacheUpdate?: (discussion: ClipDiscussion) => void;
}

export const useCreateDiscussion = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (variables: CreateDiscussionParams) => {
      const discussionParams = getDiscussionsParams(
        variables.newComment,
        variables.type,
        variables.clipId,
        variables.board,
        variables.timestamp,
        variables.annotation,
      );

      return Discussions.create(discussionParams, variables.shortId);
    },
    onMutate: async (variables: CreateDiscussionParams) => {
      // Cancel any outgoing refetches so they don't overwrite our optimistic update
      await queryClient.cancelQueries({ queryKey: variables.key });

      // Create the optimistic discussion
      const discussionParams = getDiscussionsParams(
        variables.newComment,
        variables.type,
        variables.clipId,
        variables.board,
        variables.timestamp,
        variables.annotation,
      );

      const newDiscussionWithTempId = {
        ...discussionParams.discussion,
        id: NEW_DISCUSSION_ID,
      };
      const newCommentWithTempId = {
        ...discussionParams.comment,
        id: uuidv4(),
      };

      const newDiscussion = mockDiscussion({
        assetId: variables.assetId,
        account: variables.account,
        clipId: variables.clipId,
        currentVersion: variables.currentVersion,
        board: variables.board,
        newComment: newCommentWithTempId,
        newDiscussion: newDiscussionWithTempId,
      });

      variables.beforeCacheUpdate?.(newDiscussion);

      // Snapshot the previous value
      const predicate = ({ queryKey }: Query<unknown, Error, unknown, QueryKey>) => {
        if (isClipDiscussionsKey(queryKey)) {
          const canAddToFilter =
            !queryKey[1].discussionsFilter ||
            queryKey[1].discussionsFilter === ASSET_MODAL_DISCUSSION_FILTERS.OPENED ||
            queryKey[1].discussionsFilter === ASSET_MODAL_DISCUSSION_FILTERS.ALL;

          const isSameClip = queryKey[1].clipId === variables.clipId || !queryKey[1].clipId;

          return isSameClip && queryKey[1].assetId === variables.assetId && canAddToFilter;
        }
        return false;
      };

      const previousDiscussions = queryClient.getQueriesData<ClipDiscussionsCacheType>({ predicate });

      // Optimistically update the cache
      queryClient.setQueriesData<ClipDiscussionsCacheType>({ predicate }, (data) =>
        createDiscussionInCache(newDiscussion, data),
      );

      // Return a context object with the snapshot
      return { previousDiscussions };
    },
    onError: (err, variables: CreateDiscussionParams, context) => {
      // If the mutation fails, roll back to the previous state
      if (context?.previousDiscussions) {
        context.previousDiscussions.forEach(([queryKey, value]) => {
          queryClient.setQueryData(queryKey, value);
        });
      }

      reportErrorToBugsnag({
        error: err,
        context: 'Failed to create discussion',
        metadata: {
          data: {
            newComment: variables.newComment,
            board: variables.board,
            clipId: variables.clipId,
            assetId: variables.assetId,
            type: variables.type,
            timestamp: variables.timestamp,
          },
        },
      });
    },
    onSettled: (_data, _error, variables: CreateDiscussionParams) => {
      // Invalidate and refetch the discussions query to get the server state
      queryClient.invalidateQueries({ queryKey: variables.key });
    },
  });

  return mutation;
};
